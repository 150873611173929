<template>
<span>

    <v-toolbar flat color="white">
        <v-toolbar-title class="lime--text lighten-1">
            <h1>{{ employee.empl_nome }}</h1>
        </v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <template>
            <v-btn color="blue-grey" dark class="mb-2" :to="{name: 'employees'}">
                <v-icon dark>keyboard_arrow_left</v-icon> Voltar
            </v-btn>
        </template>
    </v-toolbar>

    <table id="table-detalhe" class="table table-bordered table-condensed">
        <tbody>
            <tr>
                <th width="10%">Empresa:</th>
                <td v-if="employee.departamento" width="90%" colspan="5">{{ employee.departamento.empresa.comp_fantasia }}</td>
                <td v-if="employee.empl_foto" rowspan="12" width="10%">
                    <img :src="`${base_url}upload/employee/${employee.empl_foto}`" width="200">
                </td>
                <td v-if="!employee.empl_foto" rowspan="12" width="10%">
                    <img :src="`${base_url}upload/employee/avatar.jpg`" width="150">
                </td>
            </tr>
            <tr>
                <th width="10%">Departamento:</th>
                <td v-if="employee.departamento" width="90%" colspan="5">{{ employee.departamento.dept_nome }}</td>
            </tr>
            <tr>
                <th width="10%">Nome:</th>
                <td width="90%" colspan="5">{{ employee.empl_nome }}</td>
            </tr>
            <tr>
                <th width="10%">Email:</th>
                <td width="90%" colspan="5">{{ employee.email }}</td>
            </tr>
            <tr>
                <th width="10%">Prefixo:</th>
                <td width="40%" colspan="2">{{ employee.empl_prefixo }}</td>
                <th width="10%">Telefone:</th>
                <td width="40%" colspan="2">{{ employee.empl_fone }}</td>
            </tr>
            <tr>
                <th width="10%">Celular:</th>
                <td width="90%" colspan="5">{{ employee.empl_celular }}</td>
            </tr>
            <tr>
                <th width="10%">Status</th>
                <td width="90%" colspan="5">{{ (employee.empl_status == '1') ? 'Ativo' : 'Inativo' }}</td>
            </tr>
        </tbody>
    </table>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../config/configs'

export default {
    created() {
        this.loadEmployee()
    },
    name: "DetailEmployeeComponent",
    props: {
        empl_id: {
            require: true
        },
    },
    data() {
        return {
            search: '',
            employee: {},
            base_url: URL_BASE
        }
    },
    methods: {
        loadEmployee() {
            this.$store.dispatch('loadEmployee', this.empl_id)
                .then(response => {
                    this.employee = response
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        type: "error",
                        title: 'Erro',
                        text: 'Dados não localizado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
    }
};
</script>

<style scoped>
.avatar {
    height: 25px;
}

.logo {
    height: 50px;
}
</style>
